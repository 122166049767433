<template>
  <div>
    <p>Logging out</p>  
  </div>
</template>

<script>
export default {    
    name: "Logout",    
    methods: {    
    },   
    mounted() {
      this.$store.dispatch("logoutUser");
    }
  }
</script>
